<script setup lang="ts">
	import { useFaqStore } from '~/stores/FaqStore';
	import { useNewsStore } from '~/stores/NewsStore';

	const faqStore = useFaqStore();
	const newsStore = useNewsStore();
	await faqStore.getList();
	await newsStore.getList();

	useHead({
	  title: 'Аренда авто посуточно без водителя – прокат легковых автомобилей недорого, доступные цены | Мой Автопрокат',
		meta: [
			{
				name: 'description',
				content: 'Компания Мой Автопрокат предлагает аренду авто без водителя для поездок по России и миру – телефон 8 (800) 777–48–48 – Собственный парк легковых автомобилей в аренду: эконом, бизнес, премиум, внедорожники, минивэны. Прокат машин для путешествий, мгновенный заказ на сайте, поддержка 24/7',
			},
		]
	})
</script>

<template>
	<div class="page">
		<MainBanner />
		<div class="content">
			<div class="container">
				<div class="content__wrapper">
					<BookingForm keep-alive />
					<div class="content__main">
						<MainLocations />
						<Reviews />
						<VideoBlock />
						<Faq />
						<News />
						<Subscription />
						<AboutBlock />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	.content {
		position: relative;
		z-index: 10;
	}
</style>