<template>
	<div class="about-block">
		<div class="about-block__image">
			<img src="/img/stone.png" alt="">
		</div>
		<div class="about-block__content">
			<p class="about-block__text">Наши преимущества — мгновенное подтверждение заказа, суперстраховка, гарантия и реальные фото выбранного автомобиля, отсутствие ограничений и скрытых сборов, высочайший уровень клиентского сервиса и доступные цены, а также забота о вас 24 часа в сутки.</p>
			<p class="about-block__text">Предоставляем услуги автопроката <br>на популярных внутренних направлениях: <NuxtLink to="regions/crimea">Крым</NuxtLink>, <NuxtLink to="regions/sochy">Сочи</NuxtLink>, <NuxtLink to="regions/adler">Адлер</NuxtLink>, <NuxtLink to="regions/anapa">Анапа</NuxtLink> и Калиниград, делая ваш отдых и деловые поездки простыми, доступными и беззаботными. <br>У нас Вы также сможете арендовать автомобиль в <NuxtLink to="">Черногории</NuxtLink>, <NuxtLink to="regions/georgia">Грузии</NuxtLink>, в <NuxtLink to="regions/turkey">Турции</NuxtLink>, на <NuxtLink to="regions/cyprus">Кипре</NuxtLink>, и во многих других странах.</p>
		</div>
	</div>
</template>