<script setup lang="ts">
import { useLocationsStore } from "~/stores/LocationsStore";
import { useCarsStore } from "~/stores/CarsStore";
const nuxtApp = useNuxtApp();
const router = useRouter();
const cars = useCarsStore();

const locationsStore = useLocationsStore();
</script>

<template>
  <div>
    <h1 class="cards__title">Арендa авто по всeй России и за рубежoм</h1>
    <div class="section cards">
      <div class="card" v-for="location in locationsStore.list">
        <div class="card__image">
          <nuxt-link :to="`/${location.slug}/`"
            ><img :src="`${location.full_card_image_path}`" alt=""
          /></nuxt-link>
        </div>
        <div class="card__title">{{ location.caption }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.cards__title {
  font-size: 24px;
  line-height: 30px;
  opacity: 0.8;
  text-align: center;
  padding-top: 40px;
}
</style>
