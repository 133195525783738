<script setup lang="ts">
	import { ref } from 'vue';
	import { useSubscriptionStore } from '~/stores/SubscriptionStore';
	import { required, minLength } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';

	const subscribe = useSubscriptionStore();
	const { $toast } = useNuxtApp();
	const loading = ref(false)

	const subscribeForm = ref({
    email: '',
  });

	const rules = computed(() => {
    return {
      email: { required, minLength: minLength(4) },
    };
  });

	const v$ = useVuelidate(rules, subscribeForm);

	const submit = async () => {
    v$.value.$validate();
    if (!v$.value.$error) {
      loading.value = true;
      await subscribe.send(subscribeForm);
      loading.value = false;
      subscribeForm.value.email = '';
      v$.value.$reset();
			$toast.success('Подписка оформлена');
    } else {
      $toast.error('Заполните поле корректно')
    }
  };
</script>

<template>
	<div class="section subscription">
		<div class="subscription__title title">Подпишитесь на нашу рассылку</div>
		<div class="subscription__text">Узнавайте первыми о наших скидках и акциях!</div>
		<div class="subscription__row">
			<div class="subscription__field">
				<input 
					type="text" 
					placeholder="Электронная почта" 
					class="form-control form-control-email"
					v-model="subscribeForm.email"
					:class="{'form-control-error': subscribe.errors.includes('data.email') || v$.email.$error}"
				>
			</div>
			<button class="button button-accent" @click="submit" v-if="!loading">Подписаться</button>
			<button class="button button-accent" v-else>...</button>
		</div>
	</div>
</template>