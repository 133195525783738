import { defineStore } from 'pinia';

export const useSubscriptionStore = defineStore('SubscriptionStore', {
    state: () => ({
      subscribeForm: null,
      errors: [],
    }),
    actions: {
      async send(payload) {
        this.errors = [];
        try {
          const { error, data: subscribeForm } = await $fetch('/api/v2/subscribe', {
            method: 'post',
            body: payload.value
          });
          if (error && error.value && error.value.data) {
            Object.values(error.value.data.errors).forEach(e => {
              useNuxtApp().$toast.error(e[0])
            })
            this.errors = Object.keys(error.value.data.errors);
          }
          if (subscribeForm) this.subscribeForm = subscribeForm.value;
        } catch (error) {
          console.log(error);
        }
      },
      clearError(error)  {
        if (this.errors.includes(error)) {
          let index = this.errors.indexOf(error);
          if (index > -1) {
            this.errors.splice(index, 1)
          }
        }
      }
    }
  });