<script setup lang="ts">
import { Navigation } from 'swiper/modules';
import { useNewsStore } from '~/stores/NewsStore';

const router = useRouter();

const newsStore = useNewsStore();

const goLink = (item: Object) => {
  router.push(`/regions/${item.branch_office_slug}/${item.slug}`);
};
</script>

<template>
	<div class="section news" v-if="newsStore && newsStore.list && newsStore.list.length > 0">
		<div class="news__title title">Интересное рядом</div>
		<div class="news__swiper">
			<Swiper
				class="swiper swiper-news"
				:direction="'horizontal'"
				:slides-per-view="1.5"
				:space-between="8"
				:loop="false"
				:speed="600"
				navigation
				:modules="[Navigation]"
				:breakpoints="{ 761:{ slidesPerView: 3, spaceBetween: 24 } }"
			>
				<SwiperSlide v-for="(item, index) in newsStore.list" :key="index">
					<div class="news__item small" @click="goLink(item)">
						<div class="news__item-image">
						<img :src="`https://mycarrental.ru/${item.image}`" alt="" v-if="item.image">
						<img src="/img/gallery-3.jpg" alt="" v-else>
						</div>
						<div class="news__item-content">
							<div v-if="item.formatted_date" class="news__item-label">{{item.formatted_date}}</div>
							<div v-if="item.title" class="news__item-title">{{item.title}}</div>
							<div v-if="item.teaser" class="news__item-text">{{item.seo_description}}</div>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	</div>
</template>

<style>
.swiper-news .news__item {
	width: 100% !important;
}
.news__item {
	cursor: pointer;
}
</style>